.footer a {
    background-image: linear-gradient(to right, rgba(247, 6, 186, 0) 50%, #2F5EF6 50%);
    background-position: -0% 0;
    background-size: 200% auto;
    color: #FFF;
    font-size: 1em;
    line-height: 1em;
    text-decoration: none;
    transition: background-position 0.5s ease-out;
}

/* Gradient animation */
.footer a:hover {
    background-position: -99.99% 0;
}

/* Space between elements and footer */
.footer {
    margin-top: 40px;
    text-align: center;
    width: 100%;
}
