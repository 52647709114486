.loader {
  --path: #2F3545;
  --dot: #5628EE;
  --duration: 2s;
  width: 24px;
  height: 24px;
  position: relative;

  svg {
      display: block;
      width: 100%;
      height: 100%;
      rect,
      polygon,
      circle {
          fill: none;
          stroke: var(--path);
          stroke-width: 10px;
          stroke-linejoin: round;
          stroke-linecap: round;
      }
      polygon {
          stroke-dasharray: 145 (221 - 145) 145 (221 - 145);
          stroke-dashoffset: 0;
          animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
      rect {
          stroke-dasharray: (256 / 4 * 3) (256 / 4) (256 / 4 * 3) (256 / 4);
          stroke-dashoffset: 0;
          animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
      circle {
          stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
          stroke-dashoffset: 75;
          animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
      }
  }
  &.triangle {
      width: 24px;
  }
}

@keyframes pathTriangle {
  33% {
      stroke-dashoffset: 74;
  }
  66% {
      stroke-dashoffset: 147;
  }
  100% {
      stroke-dashoffset: 221;
  }
}

@keyframes dotTriangle {
  33% {
      transform: translate(0, 0);
  }
  66% {
      transform: translate(10px, -18px);
  }
  100% {
      transform: translate(-10px, -18px);
  }
}
