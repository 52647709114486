.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  z-index: 1000;
}

.content {
  position: relative;
  z-index: 1001;
  margin: 32px auto;
  transform: translate(0, 0);
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #090015;
  opacity: 0.85;
  transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  z-index: 1000;
}
